<template>
  <v-container>
    <v-card rounded="lg" v-if="!$apollo.queries.me.loading">
      <v-card-title>
        {{ $t("navigation.preferences") }}
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <div class="text-h6">{{ $t("preferences.language") }}</div>
        <v-radio-group hide-details :value="me.locale" @change="setSetting('locale', 'locale', $event)">
          <v-radio v-for="item in languages" :key="item.locale" :value="item.locale">
            <template v-slot:label>
              <flag :iso="item.iso" />
              <span class="ml-2">{{ item.locale.toUpperCase() }}</span>
            </template>
          </v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-text>
        <div class="text-h6">{{ $t("preferences.dark-mode") }}</div>
        <v-switch
          :inputValue="me.darkModeEnabled === 'true'"
          inset
          hide-details="true"
          @change="setSetting('darkModeEnabled', 'dark-mode', (!!$event).toString())"
        >
          <template v-slot:label> {{ $t("enable") }} {{ $t("preferences.dark-mode") }} </template>
        </v-switch>
      </v-card-text>

      <v-card-text>
        <div class="text-h6">{{ $t("preferences.notifications.title") }}</div>
        <v-switch
          :inputValue="me.emailNotificationsEnabled === 'true'"
          inset
          hide-details="true"
          @change="setSetting('emailNotificationsEnabled', 'notifications.email', (!!$event).toString())"
        >
          <template v-slot:label> {{ $t("enable") }} {{ $t("preferences.notifications.email") }} </template>
        </v-switch>
        <v-switch
          :inputValue="me.pushNotificationsEnabled === 'true'"
          inset
          hide-details="true"
          @change="setSetting('pushNotificationsEnabled', 'notifications.push', (!!$event).toString())"
        >
          <template v-slot:label> {{ $t("enable") }} {{ $t("preferences.notifications.push") }} </template>
        </v-switch>
      </v-card-text>
    </v-card>

    <v-skeleton-loader type="card" v-else rounded="lg" />
  </v-container>
</template>

<script>
import gql from "graphql-tag";

export default {
  data() {
    return {
      languages: [
        { iso: "IT", locale: "it" },
        { iso: "GB", locale: "en" },
      ],
    };
  },

  apollo: {
    me: {
      query: gql`
        query Me {
          me {
            id
            locale: setting(key: "locale")
            darkModeEnabled: setting(key: "dark-mode")
            emailNotificationsEnabled: setting(key: "notifications.email")
            pushNotificationsEnabled: setting(key: "notifications.push")
          }
        }
      `,
    },
  },

  methods: {
    setSetting(field, key, value) {
      this.$apollo.mutate({
        mutation: gql`
          mutation SetSetting($key: String!, $value: String!) {
            setSetting(key: $key, value: $value) {
              id
              ${field}: setting(key: $key)
            }
          }
        `,
        variables: {
          key,
          value,
        },
        optimisticResponse: {
          setSetting: {
            __typename: "User",
            id: this.me.id,
            [field]: value,
          },
        },
      });
    },
  },
};
</script>
